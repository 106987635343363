import { Box, CssBaseline } from "@mui/material";
import OMLHubAppBar from "./components/OMLHubAppBar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import { FirebaseAuthProvider } from "./components/FirebaseAuthContext";
import { fAuth } from "./firebase";
import { HomePage } from "./pages/HomePage";
import { Account } from "./pages/Account";
import { Model } from "./pages/Model";
import { ModelVersionPage } from "./pages/ModelVersion";

function App() {
  return (
    <FirebaseAuthProvider auth={fAuth}>
      <CssBaseline />
      <Box sx={{ flexGrow: 1 }}>
        <BrowserRouter>
          <OMLHubAppBar />
          <Routes>
            <Route path="/" Component={HomePage}/> 
            <Route path="/login" Component={Login} /> 
            <Route path="/account" Component={Account} />
            <Route path="/model/:id" Component={Model} /> 
            <Route path="/model/:id/:version" Component={ModelVersionPage} /> 
          </Routes>
        </BrowserRouter>
      </Box>
    </FirebaseAuthProvider>
  );
}

export default App;
