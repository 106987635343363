import { useParams } from "react-router-dom";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { useState, useEffect} from "react";
import { useFirebaseAuth } from "../components/FirebaseAuthContext";
import { doc, getDoc } from "firebase/firestore";
import { fDb } from "../firebase";
import { ModelData, ModelVersion } from "../types";
import { ModelHeading } from "../components/model/ModelHeading";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { ModelMetric } from "../components/model/ModelMetric";

export function ModelVersionPage() {

    const {id, version} = useParams()
    const [loading, setLoading] = useState(true)

    const [modelData, setModelData] = useState<ModelData>({name: "", description: "" , configured: false})
    const [versionData, setVersion] = useState<ModelVersion>({})

    const user = useFirebaseAuth()

    useEffect( () => {
        const fetchData = async (uid: string, id: string, version: string) => {
            const modelDocData = (await getDoc(doc(fDb, "users", uid, "models", id))).data()
            const versionDocData = (await getDoc(doc(fDb, "users", uid, "models", id, "versions", version))).data()

            setModelData({
                name: modelDocData?.name,
                description:modelDocData?.description,
                zenodo_id: modelDocData?.zenodo_id,
                configured: modelDocData?.configured
            })

            setVersion({
                metrics: versionDocData?.metrics?.flatMap((m: any) => {
                    switch(m.type) {
                        case "TrainningEpocMetric":
                            return {
                                name: m.name,
                                epocs: m.epocs,
                                mesurements: m.measurement
                            }
                        default:
                            return undefined
                    }
                }) || []
            })
        }

        (id && version && user?.uid) && fetchData(user?.uid, id, version).then(() => setLoading(false))

    }, [user?.uid, id, version])


    const displayTraining = versionData.metrics

    if (loading) {
        return  <LoadingSpinner/>
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                    >
                        <ModelHeading modelData={modelData}/>
                    </Paper>
                </Grid>
            </Grid>

            {displayTraining && <Grid container spacing={3} mt={1}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                    >
                        <Typography variant="h5">
                            Trainning
                        </Typography>
                        
                        {
                            versionData.metrics?.map( (m) => {
                                return (
                                  <ModelMetric metric={m}  key={m.name}/>
                                )
                            })
                        }

                    </Paper>
                </Grid>
            </Grid> }
        </Container>
    )
}