import { Typography } from "@mui/material";

import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export function ModelConfig({user_id, model_id}: {user_id: string, model_id: string}) {
    const code = `
from openmlhub import model_logger 
from openmlhub.config import OpenMLHubConf

logger = model_logger(
    OpenMLHubConf('${user_id}', '_api_key_' ), 
    '${model_id}')

logger.log()
`
    const install = `
pip install openmlhub    
    `
    return (
        <>
            <Typography variant="h6" >
                Configuration
            </Typography>
            <Typography>
                Install the dependencies using pip:
            </Typography>
            <SyntaxHighlighter language="python" style={ docco }>
                {install}
            </SyntaxHighlighter>
            <Typography>
                 Add the following code to your trainning to enable tracking and recording of metrics agains OpenMLHub
            </Typography>
            <SyntaxHighlighter language="python" style={ docco }>
                {code}
            </SyntaxHighlighter>
        </>
    )
}