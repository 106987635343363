import React from "react";
import { ModelVersionItem } from "../../types";
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export function ModelVersionList( {modelId, versions} : {modelId: string, versions: Array<ModelVersionItem>}) {

 
    return (
        <React.Fragment>
            <Typography>
                Versions
            </Typography>

            <Box mt={1}>
                <Table size="small">
                    <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Last Updated</TableCell>
                        <TableCell>Created</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            versions.map( v => {
                                return (
                                    <TableRow key={v.name}>
                                        <TableCell>
                                            <Link to={`/model/${modelId}/${v.name}`}>
                                                {v.name}
                                            </Link>
                                        </TableCell>
                                        <TableCell>{v.lastUpdated}</TableCell>
                                        <TableCell>{v.firstUpdated}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody> 
                </Table>
            </Box>
        </React.Fragment>
    )

}