import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Avatar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { fAuth } from "../firebase";
import { useFirebaseAuth } from "./FirebaseAuthContext";

const LoginButton  = () => <Button color="inherit" href='login'>Login</Button>
const UserAvatar: React.FC<{displayName: string, photoUrl: string}> = ({displayName, photoUrl}) =>  {

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

 const navigate = useNavigate();

 const logout = () => {
    fAuth.signOut()
    navigate("/")
 }

  return (
    <Box sx={{ flexGrow: 0 }}>
      <IconButton onClick={handleOpenUserMenu}  sx={{ p: 0 }}>
              <Avatar alt={displayName} src={photoUrl} />
      </IconButton>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
    >
      <MenuItem key="account" onClick={() => {
          handleCloseUserMenu()
          navigate('/account')
        }
      }>
            <Typography textAlign="center">Account</Typography>
      </MenuItem>
      <MenuItem key="logout" onClick={logout}>
            <Typography textAlign="center">Logout</Typography>
      </MenuItem>
    </Menu>
  </Box>
  )
}

export default function OMLHubAppBar() {
    const user = useFirebaseAuth();

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };

    const navigate = useNavigate();
    
    return (
        <AppBar position="static">
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <CenterFocusStrongIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                OpenMLHub
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                    <MenuItem onClick={() => navigate("/")}>
                      Models
                    </MenuItem>
                </Menu>
              </Box>
             
              <Typography
                variant="h5"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1,
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                OpenMLHub
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                {user === null? <LoginButton/> :  <UserAvatar displayName={user?.displayName || ""} photoUrl={user?.photoURL || ""} />}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
    );
  }