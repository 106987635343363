import { Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { AddModel } from "./AddModel";
import { ModelItem } from "../../types"
import React from "react";

export function ModelList({models}:{models: Array<ModelItem>}) {


 
    return (
        <React.Fragment>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Open ML Models
            </Typography>
            <Table size="small">
                <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Descripion</TableCell>
                    <TableCell>Doi</TableCell>

                </TableRow>
                </TableHead>
                <TableBody>
                    {
                        models.map( m => {
                            return (
                                <TableRow key={m.id}>
                                    <TableCell><Link href={`model\\${m.id}`}>{m.name}</Link></TableCell>
                                    <TableCell>{m.description}</TableCell>
                                    <TableCell align="left">{m.doi}</TableCell>
                                </TableRow>
                               )
                        })
                    }
                </TableBody> 
            </Table>
            <AddModel/>
        </React.Fragment>
    )
}