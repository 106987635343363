import { fAuth } from "../firebase";
import { GoogleAuthProvider } from "firebase/auth";
import * as firebaseui from "firebaseui";
import { useEffect } from "react";

export default function Login() {
    const uiConfig = {
        callbacks: {
            signInSuccessWithAuthResult: (authResult: any, redirectUrl?: string) => {
                return true
            },
            signInFailure: (error: firebaseui.auth.AuthUIError) => {
                return Promise.resolve()
            }
        },
        signInOptions: [
          GoogleAuthProvider.PROVIDER_ID
        ],
        signInFlow: 'popup',
        signInSuccessUrl: "/",
        tosUrl: '<your-tos-url>',
        privacyPolicyUrl: function() {
          window.location.assign('<your-privacy-policy-url>');
        },
      };


    const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(fAuth);
    useEffect(() =>  ui.start('#firebaseui-auth-container', uiConfig))

    return (
        <div id="firebaseui-auth-container"></div>
    )
}