import { Chip, Stack, Typography } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import { ModelData } from "../../types";

export function ModelHeading({modelData}: {modelData: ModelData}) {

    return (
        <>
            <Typography variant="h5" color="primary" >
                {modelData.name}
            </Typography>
            <Typography variant="subtitle1">
                {modelData.description}
            </Typography>
            <Stack direction="row" spacing={1} mt={2}>
                <Chip
                    icon={<LinkIcon />}
                    label="Zenodo"
                    onClick={() => {
                        window.open(`https://zenodo.org/uploads/${modelData.zenodo_id}`, "_blank")
                    }}
                />
            </Stack>
        </>
    )
}