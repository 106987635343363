import { Box, Typography } from "@mui/material";
import { Metric, TrainingEpocMetric } from "../../types";
import { AxisOptions, Chart } from "react-charts";
import React from "react";

function MetricTitle({name}: {name: string}) {

    const findName = (name: string) => {switch(name) {
            case "loss":
                return ["Loss Function", "Epoc evaluation of the lost function"]
            case "f1":
                return ["F1-Score","Harmonic mean of the precision and recal"]
            case "uac":
                return ["UAC","Computed the area under the ROC-curve"]
            default:
                return ["", ""]
        }
    }
    const [nameTitle, description] = findName(name)
    return (
        <>
            <Typography variant="h6">
                {nameTitle}
            </Typography>
            <Typography variant="subtitle1">
                {description}
            </Typography>
        </>
    )
}

export function TrainingEpocChart({metric}: {metric: TrainingEpocMetric}) {
    type EpocDatum = { epoc: number, measure: number }
    const data = [
        {
            label: metric.name,
            data: metric?.mesurements.map((m, i) => {
                return {
                    epoc: i,
                    measure: m
                }
            })
        }
    ]

    const primaryAxis = React.useMemo(
        (): AxisOptions<EpocDatum> => ({
          getValue: datum => datum.epoc,
        }),
        []
      )
    
    const secondaryAxes = React.useMemo(
    (): AxisOptions<EpocDatum>[] => [
        {
         getValue: datum => datum.measure,
        },
    ],
    []
    )
    
    return (
        <Box height={200}>
            <Chart options={{
                data,
                primaryAxis,
                secondaryAxes
            }}
            />
        </Box>
    )
}

export function ModelMetric({metric} : {metric: Metric}) {

    function isTrainningEpocMetric(metric: Metric): metric is TrainingEpocMetric {
        return 'epocs' in metric
    }

    return (
        <Box mt={1}>
            <MetricTitle name={metric.name}/> {
                isTrainningEpocMetric(metric) && <TrainingEpocChart metric={metric}/>
            }
        </Box>
    )
}