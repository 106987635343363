import { Box, Button, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFirebaseAuth } from "../FirebaseAuthContext";
import { useEffect, useState } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { fDb } from "../../firebase";

export function AccountKeys() {

    const user = useFirebaseAuth()
    const [keys, setKeys] = useState<Array<String>>([])

    useEffect( () => {
        const getKeysAndSetState = async (uid: string) => {
                const keys = Array<String>()
                const userDoc = await getDoc(doc(fDb, "users", user?.uid || " "))
                const userData = userDoc.data()

                userData?.api_key_1 && keys.push(userData.api_key_1)
                userData?.api_key_2 && keys.push(userData.api_key_2)
                return(keys)
        }

        user?.uid && getKeysAndSetState(user.uid).then(keys => setKeys(keys))
    }, [user?.uid])
    
    const addKey = () => {
        // TODO: make sense to move this to backend, and use a strong key generation
        const array = new Uint32Array(64);
        const pass = [...crypto.getRandomValues(array)].map((x => {
            const code = x % 25 + 65 // UTF-16
            return String.fromCharCode(code)
            })).join('')
        
        const newKeys = [...keys]
        newKeys.push(pass)
        updateApiKeysInFirebase(newKeys)
        setKeys(newKeys)
    }

    const deleteKey = (i: number) => {
        const newKeys = [...keys]
        newKeys.splice(i,1)
        setKeys(newKeys)   
        updateApiKeysInFirebase(newKeys)   
    }

    const copyKey = (i: number) => {
        navigator.clipboard.writeText((keys[i] && keys[i].toString()) || "");
    }

    const updateApiKeysInFirebase = (keys: Array<String>) => {
        const update = async (data: {}) => {

            setDoc(doc(fDb, "users", user?.uid || ""), data, {merge: true})
        }
         update({
            api_key_1: keys[0] || "",
            api_key_2: keys[1] || ""
        })
    }

    return (<>
        <Typography variant="caption" display="block">
            Account keys enable the configuration of the reported, more 
            than one key are considered to enable key rotation
        </Typography>

        <br/>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={10}>
                <TextField
                    required
                    id="uid"
                    name="userId"
                    label="User Id"
                    fullWidth
                    variant="filled"
                    value={user?.uid || ""} 
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <IconButton size="small" onClick={() => {navigator.clipboard.writeText(user?.uid || "");}}>
                    <ContentCopyIcon />
                </IconButton>
            </Grid>
        </Grid>
        
        <Box mt={2}>
            <Typography>
                Keys:
            </Typography>
            {
                keys.map ((key, i) => {
                    return (
                        <Grid container spacing={3} mt={1} key={"r" + i}>
                            <Grid item xs={12} sm={10} key={"r" + i + "c1"}>
                                <TextField
                                    id={"k" + i}
                                    key={"k" + i}
                                    required
                                    fullWidth
                                    variant="filled"
                                    value={key}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}  key={"r" + i + "c2"}>
                                <Stack direction="row">
                                    <IconButton  size="small" onClick={() => copyKey(i)}>
                                        <ContentCopyIcon />
                                    </IconButton>
                                    <IconButton size="small" onClick={() => deleteKey(i)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Stack>
                            </Grid>
                     </Grid>
                )})
            }
        </Box>
        {
            keys.length < 2 &&
            <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={addKey}
                >
                Add Key
            </Button>
        }
    </>
    )
}