import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import { useFirebaseAuth } from "../FirebaseAuthContext";
import axios from "axios";

export function AddModel() {

    const user = useFirebaseAuth()
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [nameError, setNameError] = useState(false)

    const handleClose = () => {
        setOpen(false);
    };

    const validForm = () => {
        if (name.length < 3) {
            setNameError(true)
        }
        return name.length >= 3 
    }

    const register = async () => {
        if (!validForm()) {
            return
        }
        
        // TODO: Handle processing as it can take a bit.
        const { data, status } = await axios.post(
            'https://openmlhub.com/api/model',
            {
                name: name,
                description: description
            },
            {
              headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${await user?.getIdToken()}`
              },
            },
          );

        // TODO: Handle errors and refresh of the main data table.
        setOpen(false)
    }
    
    return (
        <>        
        <Box sx={{ alignSelf: 'end', ml: 2, mt: 2 }}>       
            <Button variant="outlined" onClick={() => setOpen(true)}>
                New Model
            </Button>
        </Box>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Register a new model</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Register a new model creates a draft disposition in your Zenodo account.
                </DialogContentText>
                <TextField
                    error = {nameError}
                    autoFocus
                    margin="dense"
                    id="modelname"
                    label="Name"
                    fullWidth
                    variant="standard"
                    onChange={event => { 
                        setName(event.target.value)
                        if (nameError && event.target.value.length >= 3){
                            setNameError(false)
                        }   
                    }}
                    helperText="Name needs at least 3 letters"
                />

                <TextField
                    autoFocus
                    margin="dense"
                    id="description"
                    label="Description"
                    fullWidth
                    variant="standard"
                    onChange={event => {setDescription(event.target.value)}}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={register}>Register</Button>
            </DialogActions>
        </Dialog>
        </>
    )
}