import { Box, Button, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import { User } from "firebase/auth";
import { useFirebaseAuth } from "../components/FirebaseAuthContext";
import { ModelList } from "../components/dashboard/ModelList";
import { ModelItem } from "../types";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { fDb } from "../firebase";
import { LoadingSpinner } from "../components/LoadingSpinner";

const UnAuthCTA = () => {
    return (
        <Box
        sx={{
        bgcolor: 'background.paper',
        pt: 8,
        pb: 6,
        }}
        >
        <Container maxWidth="sm">
        <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
        >
           OpenML Hub
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
            "Discover 'OpenML Hub,' a collaborative, serverless platform for open ML models, powered by Zenodo for hassle-free storage.
             Aligned with open science principles, ModelHub prioritizes transparency and accessibility. With built-in features like version tracking and analytics,
            it streamlines your research journey, making efficiency and ethical innovation the focus of your exploration."
        </Typography>
        <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center"
        >
            <Button variant="contained" href="/login">Start Here</Button>
        </Stack>
        </Container>
        </Box>
    )
}

const Dashboard = ({userId}: {userId: string}) => {

    const [models , setModels] = useState(Array<ModelItem>())
    const [loading, setLoading] = useState(true)

    useEffect( () => {
        getDocs(collection(fDb, "users", userId, "models")).then( docs => {
                const models: Array<ModelItem> = []
                docs.forEach( doc => {
                    const modelData = doc.data()
                    const model = {
                        id: modelData.id,
                        name: modelData.name,
                        description: modelData.description,
                        doi: modelData.doi
                    }
                    models.push(model)
                });
                setModels(models)
                setLoading(false)
            }
        )
    }, [userId])

    if (loading) {
        return <LoadingSpinner/>
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>

           {/* List of ml model  */}
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
                <ModelList models={models}/>
            </Paper>
          </Grid>
        </Grid>

      </Container>
    )
}

export function HomePage() {
    const user: User | null = useFirebaseAuth();
    return (
        <>
            {user === null ? <UnAuthCTA /> : <Dashboard userId={user.uid}/>}
        </>
    );
}



