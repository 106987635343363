// FirebaseAuthContext.tsx
import * as React from "react";
import { User as FUser, onAuthStateChanged, Auth} from "firebase/auth";

type User = FUser | null;
type ContextState = { user: User };

const FirebaseAuthContext =
  React.createContext<ContextState | undefined>(undefined);

interface FirebaseAuthProviderProps {
    auth: Auth,
    children: any
}

const FirebaseAuthProvider: React.FC<FirebaseAuthProviderProps> = ( props: FirebaseAuthProviderProps ) => {

  const [user, setUser] = React.useState<User>(null);
  const value = { user };

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(props.auth, setUser)
    return unsubscribe;
  }, [props.auth]);

  return (
    <FirebaseAuthContext.Provider value={value}>
      {props.children}
    </FirebaseAuthContext.Provider>
  );
};

function useFirebaseAuth() {
    const context = React.useContext(FirebaseAuthContext);
    if (context === undefined) {
        throw new Error(
        "useFirebaseAuth must be used within a FirebaseAuthProvider"
    );
    }
    return context.user;
}
  
export { FirebaseAuthProvider, useFirebaseAuth};

