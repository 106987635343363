import {  Container, Paper } from "@mui/material";
import React from "react";
import { CloseAccount } from "../components/account/CloseAccount";
import { ZenodoKey } from "../components/account/ZenodoKey";
import { AccountKeys } from "../components/account/AccountKeys";

export function Account() {

    return (
        <React.Fragment>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <AccountKeys />
                </Paper>    
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <ZenodoKey />
                </Paper>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <CloseAccount/>
                </Paper>
            </Container>
      </React.Fragment>
    )
}