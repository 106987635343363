import { Button, Typography } from "@mui/material";

export function CloseAccount() {
    //TODO implement close account logic
    
    return (
        <>
            <Typography variant="caption" display="block">
                Closing and account will delete all data associated to OpenML Hub, however it keeps all data 
                store in your Zenodo account.
            </Typography>
            <Button
                type="submit"
                color="error"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Close Account
            </Button>
        </>
    )
}