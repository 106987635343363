import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, Auth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCQm16EpPl9UpLJwKvZsZ2HGWc455pkYLI",
  authDomain: "openmlhub.firebaseapp.com",
  projectId: "openmlhub",
  storageBucket: "openmlhub.appspot.com",
  messagingSenderId: "48078875140",
  appId: "1:48078875140:web:18f20c3f05c6350d4a2351",
  measurementId: "G-H0XP562LM9"
};

export const fbApp = initializeApp(firebaseConfig);
export const fAnalytics = getAnalytics(fbApp);
export const fAuth: Auth = getAuth(fbApp)
export const fDb = getFirestore(fbApp);