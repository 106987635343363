import { Button, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useFirebaseAuth } from "../FirebaseAuthContext";
import { useState } from "react";
import { fDb } from "../../firebase"
import { getDoc, doc } from "firebase/firestore";

export function ZenodoKey() {

    const user = useFirebaseAuth()
    
    const [zenodoKey, setZenodoKey] = useState("");

    const upsertZenodoKey = async () => {        
        interface ZenodoUpsertResponse {}

        const { data, status } = await axios.post<ZenodoUpsertResponse>(
            'https://openmlhub.com/api/zenodo',
            {
                key: zenodoKey
            },
            {
              headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${await user?.getIdToken()}`
              },
            },
          );
    }

    if (user) {
        getDoc(doc(fDb, "users", user?.uid || " ")).then(doc => {
            const data = doc.data()
            if (data && 'zenodoKey' in data) {
                setZenodoKey('************** -' + data.zenodoKey.substring(data.zenodoKey.length - 3) )
            }
        })
    }

    const label = (zenodoKey && zenodoKey.length > 1) ? "Update" : "Add"


    return (<>
        <Typography variant="caption" display="block">
            OpenML Hub storage you Zenodo API encrypted to enable us access to publications
            upload the models under your Zenodo account.
        </Typography>
        <br/>
        <Typography variant="caption" display="block">
            If you do not have an account you can create one <a href="https://zenodo.org/account/settings/applications/tokens/new/">here</a>
        </Typography>


        <Grid container spacing={3}>
            <Grid item xs={12} sm={10}>
                <TextField
                    required
                    id="zendoKey"
                    name="zenodoKey"
                    label="Zenodo Key"
                    fullWidth
                    variant="standard"
                    value={zenodoKey}
                    onChange={event => setZenodoKey(event.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <Button onClick={upsertZenodoKey}>{label}</Button>
            </Grid>
        </Grid>
    </>
    )
}