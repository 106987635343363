import { Container, Grid, Paper } from "@mui/material"
import { useParams } from "react-router-dom"
import { ModelHeading } from "../components/model/ModelHeading";
import { ModelConfig } from "../components/model/ModelConfig";
import { useFirebaseAuth } from "../components/FirebaseAuthContext";
import { useEffect, useState } from "react";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { fDb } from "../firebase";
import { ModelData, ModelVersionItem } from "../types";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { ModelVersionList } from "../components/model/ModelVersionList";

export function Model() {

    const {id} = useParams()
    const user = useFirebaseAuth()
    const [loading, setLoading] = useState(true);

    const [modelData, setModelData] = useState<ModelData>({name: "", description: "" , configured: false})
    const [versions, setVersions] = useState<Array<ModelVersionItem>>([])

    useEffect (() => {
        const fetchModelData = async (userid: string) => {
            const model = await getDoc(doc(fDb, "users", userid, "models", id || " "))
            const data = model.data()
            setModelData({
                name: data?.name,
                description: data?.description,
                zenodo_id: data?.zenodo_id,
                configured: data?.configured
            })
        }
        const fetchModelVersions = async (userid: string) => {
           const versions = await getDocs(collection(fDb, "users", userid, "models", id || " ", "versions")) 
           const modelVersions = Array<ModelVersionItem>()
           versions.forEach( version => {
                const versionData = version.data()
                modelVersions.push({
                    name: versionData?.version,
                    lastUpdated: versionData?.last_updated,
                    firstUpdated: versionData?.first_published
                })
           })
           setVersions(modelVersions)
        }
        user !== null && fetchModelData(user.uid)
            .then(() => fetchModelVersions(user.uid))
            .then(() => setLoading(false))
    },[user, id])

    const displayHelp = !modelData.configured

    if (loading) {
        return <LoadingSpinner />
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                    >
                        <ModelHeading modelData={modelData}/>
                    </Paper>
                </Grid>
            </Grid>

            {/* Display configure help if there is not data reported: */}
            {displayHelp &&<Grid container spacing={3} mt={1}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                    >
                        <ModelConfig user_id={user?.uid || ""} model_id={id || ""}/>
                    </Paper>
                </Grid>
            </Grid>}

            {!displayHelp && <Grid container spacing={3} mt={1}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                    >
                       <ModelVersionList modelId={id || ""} versions={versions} />
                    </Paper>
                </Grid>
            </Grid>}

        </Container>
    )
}